import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'left',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  },
  {
    label: '在批阶段',
    prop: 'approvalRecordCount',
    itemType: 'input',
    options: [],
    input: false,
    sortable: false
  },
  {
    label: '采购部门',
    prop: 'dept_name',
    otherProp: 'deptNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false
  },
  { label: '报价单号', prop: 'qutc_no', itemType: 'input', input: true, sortable: false },
  { label: '我司货号', prop: 'prod_nos', itemType: 'input', input: false, sortable: false },
  { label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false },
  { label: '工厂打样单号', prop: 'fequ_no', itemType: 'input', input: true, sortable: false },
  { label: '货号后缀', prop: 'prod_poststfix', itemType: 'input', input: false, sortable: false },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    otherProp: 'custAbbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    otherProp: 'suppAbbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '核价接收人',
    prop: 'qutc_pricer_name',
    otherProp: 'qutcPricerList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '核价时间',
    prop: 'qutc_pridate',
    itemType: 'input',
    input: false,
    sortable: false,
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '录入人',
    prop: 'stff_name',
    otherProp: 'requStffNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    valueFormat: 'timestamp',
    format: 'yyyy-MM-dd',
    formatter: val => getDateNoTime(val, true)
  }
];
