<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          size="small"
          class="vg_ml_16 topDateRangeSearch"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="核价开始日期"
          end-placeholder="核价结束日期"
        >
        </el-date-picker>
        <el-date-picker
          size="small"
          class="vg_ml_16 topDateRangeSearch"
          v-model="dateRange2"
          type="daterange"
          range-separator="至"
          start-placeholder="录入开始日期"
          end-placeholder="录入结束日期"
        >
        </el-date-picker>
        <el-button size="small" type="primary" icon="el-icon-search" @click="getQtucs()" class="vg_ml_16">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
        <el-button type="danger" size="small" class="vd_export" @click="buttonDel()">删除</el-button>
      </div>
      <DynamicUTable
        ref="searchTable"
        :need-search="true"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :table-data="tableData"
        :need-page-sizes="true"
        :need-fixed-height="true"
        :page-size="50"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getQtucs"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post } from '@api/request';
import { qtucAPI } from '@api/modules/qtuc';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/DevelopManagement/QtucManage/qtuc';
import { getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';
import { cloneDeep } from 'lodash';
import { setTime } from '@api/public';
import DynamicUTable from '@/components/table/DynamicUTable.vue';

export default {
  name: 'QtucList',
  components: {
    DynamicUTable,
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      tableProperties: tableProperties,
      loadFlag: false,
      requStffList: [],
      requDeptList: [],
      requTakerList: [],
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: { delete: true }
    };
  },
  async mounted() {
    await this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/qutc_add' || from.path === '/qutc_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getQtucs();
    },
    getQtucs() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.searchTable.searchForm);
      setTime(searchForm, 'start_time', 'end_time', this.dateRange, true);
      setTime(searchForm, 'start_time2', 'end_time2', this.dateRange2, true);
      searchForm.create_time = searchForm.create_time ? searchForm.create_time / 1000 : null;
      searchForm.custAbbrList = searchForm.custAbbrList?.toString();
      searchForm.suppAbbrList = searchForm.suppAbbrList?.toString();
      searchForm.qutcPricerList = searchForm.qutcPricerList?.toString();
      searchForm.requStffNameList = searchForm.requStffNameList?.toString();
      searchForm.deptNameList = searchForm.deptNameList?.toString();
      getNoCatch(qtucAPI.getQutcs, searchForm).then(({ data }) => {
        this.tableProperties.forEach(item => {
          if (item.otherProp === 'custAbbrList') item.options = data.custAbbrList;
          if (item.otherProp === 'suppAbbrList') item.options = data.suppAbbrList;
          if (item.otherProp === 'qutcPricerList') item.options = data.qutcPricerList;
          if (item.otherProp === 'requStffNameList') item.options = data.requStffNameList;
          if (item.otherProp === 'deptNameList') item.options = data.deptNameList;
        });
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.searchTable.resetFields();
      this.getQtucs();
    },
    buttonDel() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据!');
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          qtucAPI.delete_qutc_by_ids({ qutc_id_list: getArrayIds(this.multiSelection, 'qutc_id').toString() }).then(({ data }) => {
            this.$message.success('删除成功!');
            this.getQtucs();
          });
        })
        .catch(err => {
          console.error(err);
          this.$message.info('取消删除');
        });
    },
    // 多选获取勾选内容
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.searchTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.qutc_id);
      });
      post(qtucAPI.deleteQutcByIds, { qutc_id_list: ids })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/qutc_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.qutc_id
          })
        )
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
